import { useState, useEffect, useContext, useRef } from "react";
import {
  FaPhone,
  FaPhoneSlash,
  FaChevronDown,
  FaChevronUp,
} from "react-icons/fa";
import CounterButton from "./CounterButton";
import useSound from "use-sound";
import victorySong from "../assets/sounds/victorySong.mp3";
import { SongsContext } from "../context/SongsContext";
import styve from "./../assets/images/styve.png";

function Team2Mobile({
  i,
  setShowCountdown,
  showCountdown,
  showWinner,
  setShowWinner,
  maxScore,
  setWinner,
  initTeamName,
  score,
  updateScore,
}) {
  const [state, dispatch] = useContext(SongsContext);
  const [play, { stop }] = useSound(victorySong);
  //const [score, setScore] = useState(0);
  const [usedPhone, setUsedPhone] = useState(false);
  const [teamName, setTeamName] = useState(initTeamName);
  const teamNameRef2 = useRef(initTeamName);

  useEffect(() => {
    if (initTeamName) {
      teamNameRef2.current.textContent = initTeamName;
    } else {
      teamNameRef2.current.textContent = "Lag 2";
    }
  }, []);

  useEffect(() => {
    if (score === maxScore) {
      // play();
      setShowWinner(true);
      setWinner(i);
    }
  }, [score, maxScore, i, play, setShowWinner, setWinner]);

  // useEffect(() => {
  //   localStorage.setItem("team" + i.toString() + "name", teamName);
  // }, [teamName, i]);

  return (
    <div
      style={{
        width: "48%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        paddingTop: "1vw",
        paddingRight: "1vw",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "40vw",
          alignItems: "flex-end",
          justifyContent: "center",
          marginTop: 10,
        }}
      >
        <div
          ref={teamNameRef2}
          style={{
            width: "100%",
            //maxHeight: "8vh",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 1,
            textOverflow: "ellipsis",
            overflow: "hidden",
            fontSize: "clamp(2vh,2vh, 4vh)",
            marginBottom: 20,
            userSelect: "none",
            textAlign: "right",
            maxLength: "15",
          }}
          contentEditable="true"
          type="text"
          spellCheck="false"
          onInput={(e) => {
            let newValue = e.currentTarget.textContent.slice(0, 14);
            setTeamName(newValue);
          }}
        />
        <div
          style={{
            cursor: "pointer",
            //height: "20%",
            //maxHeight: "20%",
            fontSize: 12,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingtop: 5,
          }}
          onClick={() => {
            setUsedPhone(!usedPhone);
            setShowCountdown(!showCountdown);
          }}
        >
          {!usedPhone ? (
            <FaPhone size="24" color="white" />
          ) : (
            <FaPhoneSlash size="24" color="red" />
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          //marginRight: "1vw",
          //marginLeft: "1vw",
          height: "15vh",
          width: "40vw",
        }}
      >
        <div
          style={{
            height: "15vh",
            width: "20vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "none",
          }}
        >
          <div
            //className="plusCounterButton"
            style={{
              cursor: "pointer",
              height: "6vh",
              width: "20vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#262f43",
              borderRadius: "10px",
              color: "green",
            }}
            onClick={() => {
              let newScore = Number(score) + 1;
              updateScore(newScore);
              if (maxScore > 0 && newScore === maxScore) {
                // play();
                setShowWinner(true);
                // setWinner(i);
              }
            }}
          >
            <FaChevronUp color="green" />
          </div>
          <div
            //className="minusCounterButton"
            style={{
              cursor: "pointer",
              height: "6vh",
              width: "20vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#262f43",
              borderRadius: "10px",
              color: "red",
            }}
            onClick={() => updateScore(Number(score) - 1)}
          >
            <FaChevronDown color="red" />
          </div>
        </div>
        <div
          style={{
            fontSize: "10vh",
            height: "15vh",
            width: "20vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            userSelect: "none",
          }}
        >
          <div>{Number(score)}</div>
        </div>
      </div>
    </div>
  );
}

export default Team2Mobile;
