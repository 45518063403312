import "../App.css";
import NumberBox from "../components/NumberBox";
import Team1 from "../components/Team1";
import Team2 from "../components/Team2";
import { FaTimes, FaEye, FaSignOutAlt, FaForward } from "react-icons/fa";
import Confetti from "react-confetti";
import dancingGif from "../assets/gifs/dancing.gif";
import { useEffect } from "react";
import dyrhaug from "../assets/images/dyrhaug.png";

const GameSession = ({
  sessionData,
  endGame,
  words,
  title,
  redNumbers,
  updateSong,
  updateSessionData,
  updateMaxScore,
  artist,
  updateShowBoxes,
}) => {
  
  

  return (
    // <div>Halla GameSession</div>
    <>
      {!sessionData.songList ? (
        <div style={{ color: "white" }}>Loading..</div>
      ) : (
        <header className="App-header">
          <div
            className="infoHeader"
            style={{
              fontSize: 14,
              width: "100vw",
              height: "5vh",
              backgroundColor: "#3d3d5b",
              borderBottomRightRadius: 20,
              borderBottomLeftRadius: 20,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                cursor: "pointer",
                padding: 5,
                //backgroundColor: "red",
                height: "40%",
                width: "15%",
                borderRadius: 5,
                color: "red",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
                //border: "1px solid red"
              }}
              onClick={() => {
                let shouldCancel = window.confirm("Avslutte spillet?");
                if (shouldCancel) {
                  endGame();
                } else {
                  return;
                }
              }}
            >
              <FaSignOutAlt color="red" />
              <div
                style={{ marginLeft: 5, maxFontSize: 10, overflow: "hidden" }}
              >
                Avslutt spill
              </div>
            </div>
            <div
              style={{
                padding: 5,
                height: "40%",
                width: "40%",
              }}
            >
              {sessionData.currentIndex + 1}/
              {sessionData.songList?.songs?.length} sanger
            </div>
            {/*}
                        <div
                            style={{
                                cursor: "pointer",
                                padding: 5,
                                //backgroundColor: "red",
                                height: "40%",
                                width: "10%",
                                borderRadius: 5,
                                //border: "1px solid white"
                            }}
                            onClick={() => {

                            }}
                        >
                            Regler
                        </div>
                        */}
            <div
              style={{
                width: "15%",
                flexDirection: "row",
                display: "flex",
                justifyContent: "flex-end",
                userSelect: "none",
                textAlign: "center",
                marginRight: "2vw",
              }}
            >
              Først til
              <div
                style={{ marginLeft: 7 }}
                contentEditable="true"
                onInput={(e) =>
                  updateMaxScore(Number(e.currentTarget.textContent))
                }
              >
                {" "}
                {sessionData.maxScore}
              </div>
            </div>
          </div>

          <div
            className="allNumbers"
            style={{
              display: "flex",
              flexDirection: "row",
              //minWidth: '80vw',
              //marginLeft: '2.5%',
              height: "25vh",
              //backgroundColor: 'white',
              alignItems: "center",
              justifyContent: "center",
              //backgroundColor: "#23262d",
              marginTop: "5vh",
              borderRadius: 10,
              overflow: "hidden",
            }}
          >
            {words.map((word, i, words) => {
              return (
                <NumberBox
                  i={i}
                  key={word + title + i}
                  word={word}
                  backgroundColor={
                    sessionData.redNumbers?.includes(i) ? "red" : "blue"
                  }
                  words={words}
                  showAnswer={sessionData.showAnswer}
                  showBoxes={sessionData.showBoxes}
                  updateShowBoxes={updateShowBoxes}
                  sessionStorage={sessionData}
                />
              );
            })}
          </div>
          <div style={{ height: "20vh", width: "100%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  //minHeight: '20vh',
                  //maxHeight: '40vh',
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {sessionData.showAnswer && (
                  <div
                    style={{
                      marginTop: 30,
                      color: "white",
                      fontFamily: "Roboto",
                      height: "70%",
                      minWidth: "30vw",
                      fontSize: "7vh",
                    }}
                  >
                    <div>{title.toUpperCase()}</div>
                    <div>{artist}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              height: "40vh",
              marginBottom: "15vh",
              width: "95%",
              //padding: '1vh',
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "33%",
                height: "100%",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {sessionData.showCountdown && (
                <>
                  <iframe
                    className="video"
                    width={"100%"}
                    height="100%"
                    title="Youtube player"
                    sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
                    src={`https://youtube.com/embed/8tAXbkGR_-c?start=9`}
                  ></iframe>
                  <div
                    style={{ cursor: "pointer", marginTop: "1vh" }}
                    onClick={() => updateSessionData({ showCountdown: false })}
                  >
                    <FaTimes />
                  </div>
                </>
              )}
              {sessionData.showWinner && (
                <div style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '60%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>
                <img
                  className="App-logo"
                  height="80%"
                  src={dancingGif}
                  alt="dancing"
                  />
                </div>
              )}
              {sessionData.showWinner && (
                <div
                  style={{
                    position: 'absolute',
                    top: "65%",
                    right: "45vw",
                    cursor: "pointer",
                    marginTop: "1vh",
                    padding: "1vh",
                    width: "10vw",
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 10,
                    zIndex: 1000
                  }}
                  onClick={() => updateSessionData({ showWinner: false })}
                >
                  <div style={{fontSize: 20}}>Lukk</div>
                  <FaTimes size={20} />
                </div>
              )}
              {/* {!sessionData.showWinner && !sessionData.showCountdown && (
                <div
                  className="button"
                  style={{
                    justifyContent: "space-around",
                    display: "flex",
                    height: "8vh",
                    width: "60%",
                    flexDirection: "column",
                    alignItems: "center",
                    border: "0px solid white",
                    borderRadius: 10,
                    marginBottom: "5vh",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "space-around",
                      width: "100%",
                    }}
                  >
                    {!sessionData.showAnswer && (
                      <div
                        style={{
                          flexDirection: "row",
                          width: "90%",
                          color: "white",
                          cursor: "pointer",
                          userSelect: "none",
                          //margin: "2vh",
                          //marginTop: "4vh",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                          overflow: "hidden",
                        }}
                        onClick={() =>
                          updateSessionData({
                            showAnswer: !sessionData.showAnswer,
                          })
                        }
                      >
                        <FaEye size="5vh" />
                        <div
                          style={{
                            marginLeft: "1vw",
                            maxFontSize: 30,
                            overflow: "hidden",
                          }}
                        >
                          Vis fasit
                        </div>
                      </div>
                    )}
                    {sessionData.showAnswer && (
                      <div
                        style={{
                          flexDirection: "row",
                          width: "90%",
                          color: "white",
                          cursor: "pointer",
                          userSelect: "none",
                          //margin: "2vh",
                          //marginTop: "4vh",
                          justifyContent: "center",
                          alignItems: "center",
                          display: "flex",
                        }}
                        onClick={() => {
                          //setShowAnswer(false);
                          //setNumberOfSongs(numberOfSongs + 1);
                          //updateIndex();
                          updateSong();
                        }}
                      >
                        <FaForward size="5vh" />
                        <div style={{ marginLeft: "1vw", fontSize: 30 }}>
                          Neste sang
                        </div>
                      </div>
                    )}
                  </div>
                </div> */}

              
            </div>
          </div>
          <div
            className="bottomBar"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              height: "20vh",
              maxHeight: "20vh",
              overflow: "hidden",
              width: "100%",
              borderTopLeftRadius: 50,
              borderTopRightRadius: 50,
            }}
          >
            <Team1
              //setWinner={sessionData.setWinner}
              score={sessionData.team1.score}
              i={"1"}
              maxScore={sessionData.maxScore}
              showWinner={sessionData.showWinner}
              // setShowWinner={() => setShowWinner}
              //setShowCountdown={sessionData.setShowCountdown}
              showCountdown={sessionData.showCountdown}
              initTeamName={sessionData.team1.name}
              //updateScore={sessionData.updateScore}
              usedPhone={sessionData.team1.usedPhone}
              //setUsedPhone={sessionData.setTeam1UsedPhone}
              updateSessionData={updateSessionData}
              sessionData={sessionData}
            />

            {/* <div
              style={{ width: 2, height: "12vh", backgroundColor: "white" }}
            /> */}
            <Team2
              //setWinner={setWinner}
              score={sessionData.team2.score}
              i="2"
              maxScore={sessionData.maxScore}
              showWinner={sessionData.showWinner}
              // setShowWinner={() => setShowWinner}
              //setShowCountdown={setShowCountdown}
              showCountdown={sessionData.showCountdown}
              initTeamName={sessionData.team2.name}
              usedPhone={sessionData.team2.usedPhone}
              //updateScore={updateScore}
              //setUsedPhone={setTeam1UsedPhone}
              updateSessionData={updateSessionData}
            />
          </div>
          <div
            style={{
              position: "absolute",
              height: "35vh",
              width: "23vw",
              // backgroundColor: "black",
              bottom: 0,
              marginLeft: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
            }}
          >
            <img
              src={dyrhaug}
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
              alt="Ivar dyrhaug"
            />
          </div>
        </header>
      )}
      {sessionData.showWinner && <div style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}><Confetti numberOfPieces={500} /></div>}
    </>
  );
};

export default GameSession;
