import "./App.css";
import AdminDashboard from "./components/AdminDashboard";
import { Route, Routes } from "react-router-dom";
import Home from "./routes/Home";
import GameSession from "./routes/GameSession";
import ControlDashboard from "./routes/ControlDashboard";
import GameController from "./routes/GameController";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/setup" element={<AdminDashboard />} />
        <Route
          path="/game/:sessionId"
          element={<GameController isAdmin={false} />}
        />
        <Route
          path="/control/:sessionId"
          element={<GameController isAdmin={true} />}
        />
      </Routes>
    </div>
  );
}

export default App;
