import { useEffect, useReducer, useState, useContext } from "react";

// import the stylesheet
import SongPicker from "./SongPicker";
import "./AdminDashboard.css";
import { initialState, reducer } from "../context/reducer";
import { SongsContext } from "../context/SongsContext";
import Fasit from "./Fasit";
import Information from "./Information";
import StepWizard from "react-step-wizard";
import HaveFun from "./HaveFun";
import MessageContainer from "./MessageContainer";

const ActionButtons = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "90%",
        marginLeft: "5%",
        justifyContent: "space-between",
        marginTop: 20,
      }}
    >
      <div
        className="button"
        style={{
          height: 50,
          width: 150,
          border: "1px solid white",
          color: "white",
          borderRadius: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={() => props.previousStep()}
      >
        Tilbake
      </div>
      <div
        className="button"
        style={{
          height: 50,
          width: 150,
          backgroundColor: "#387cf6",
          color: "white",
          borderRadius: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={props.nextStep}
      >
        {props.currentStep === props.totalSteps ? "KJØH" : "Neste"}
      </div>
    </div>
  );
};

function AdminDashboard({ setHasFinishedSetup }) {
  const [stepWizard, setStepWizard] = useState(null);
  const [state, dispatch] = useContext(SongsContext);
  const [activeStep, setActiveStep] = useState(0);

  const assignStepWizard = (instance) => {
    setStepWizard(instance);
  };

  function handleStepChange(number) {
    // return a boolean
    setActiveStep(activeStep + number);
  }

  function step1Validator() {
    // return a boolean
    return true;
  }

  // setup step validators, will be called before proceeding to the next step
  const step2Validator = () => {
    // return a boolean
    return state.selectedSongList?.songs?.length > 0;
  };

  function step3Validator() {
    // return a boolean
    return state.isCopiedToClipboard || state.rememberSelf;
  }

  function step4Validator() {
    // return a boolean
    setHasFinishedSetup(true);
  }

  function onFormSubmit() {
    // handle the submit logic here
    // This function will be executed at the last step
    // when the submit button (next button in the previous steps) is pressed
  }

  const Step1Content = (props) => {
    const validate = () => {
      if (step1Validator()) {
        props.nextStep();
        handleStepChange(1);
      }
    };
    const goBack = () => {
      return;
    };
    return (
      <div>
        <Information />
        <ActionButtons
          {...props}
          nextStep={() => validate()}
          previousStep={goBack}
        />
      </div>
    );
  };
  const Step2Content = (props) => {
    const [message, setMessage] = useState("");
    const validate = () => {
      if (step2Validator()) {
        props.nextStep();
        handleStepChange(1);
      } else {
        setMessage("Du må velge en spilleliste!");
      }
    };
    const goBack = () => {
      props.previousStep();
      handleStepChange(-1);
    };
    return (
      <div>
        <SongPicker setMessage={setMessage} />
        <MessageContainer message={message} />
        <ActionButtons {...props} nextStep={validate} previousStep={goBack} />
      </div>
    );
  };
  const Step3Content = (props) => {
    const [message, setMessage] = useState("");
    const validate = () => {
      if (step3Validator()) {
        props.nextStep();
        handleStepChange(1);
      } else {
        setMessage(
          "Du må kopiere fasiten eller bekrefte at du forstår konsekvensene av å ikke gjøre det."
        );
      }
    };
    const goBack = () => {
      props.previousStep();
      handleStepChange(-1);
    };
    return (
      <div>
        <Fasit />
        <MessageContainer message={message} />
        <ActionButtons {...props} nextStep={validate} previousStep={goBack} />
      </div>
    );
  };
  const Step4Content = (props) => {
    const validate = () => {
      //Go to game
      step4Validator();
    };
    const goBack = () => {
      props.previousStep();
      handleStepChange(-1);
    };
    return (
      <div>
        <HaveFun />
        <ActionButtons {...props} nextStep={validate} previousStep={goBack} />
      </div>
    );
  };

  const steps = ["Info", "Velg sanger", "Send fasit", "Ha det gøy!"];

  return (
    <div
      style={{
        height: "100vh",
        width: "100vw",
        backgroundColor: "#161f33",
      }}
    >
      <SongPicker setMessage={() => {}} />
    </div>
  );
}

export default AdminDashboard;
