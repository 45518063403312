const PlaylistInput = ({ value, onChange, placeholder, extraStyle }) => {
  return (
    <input
      type="text"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      style={{
        width: "90%",
        padding: "10px",
        margin: "5px 20px",
        borderRadius: "5px",
        border: "none",
        ...extraStyle,
      }}
    />
  );
};

export default PlaylistInput;
