import { useState, useContext, useEffect } from "react";
import { FaPlus, FaCheck, FaMusic } from "react-icons/fa";
import { SongsContext } from "../context/SongsContext";

function SelectedSongsItem({ song, newSongs, setNewSongs }) {
  const [state, dispatch] = useContext(SongsContext);
  const [isSelected, setIsSelected] = useState(newSongs.includes(song));

  const removeSong = (title) => {
    setNewSongs(newSongs.filter((song) => song.title !== title));
  };
  const addSong = (song) => {
    setNewSongs([...newSongs, song]);
  };
  return (
    <div
      style={{
        width: "20%",
        margin: "0.5vh",
        borderRadius: "0.5vh",
        border: isSelected ? "1px solid #4db193" : "1px solid white",
        padding: "0.5vh",
        backgroundColor: isSelected ? "#4db193" : "#282c34",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "row",
        position: "relative",
        overflow: "hidden",
        cursor: "pointer",
      }}
      onClick={() => {
        isSelected
          ? //Remove from array
            removeSong(song.title)
          : //Add to array
            addSong(song);
        setIsSelected(!isSelected);
      }}
    >
      <div style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            marginBottom: 2,
          }}
        >
          <FaMusic color={isSelected ? "black" : "white"} />
          <div
            style={{
              color: isSelected ? "black" : "white",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginLeft: 10,
            }}
          >
            <div style={{ fontSize: 14 }}>{song.title}</div>
            <div style={{ fontSize: 12 }}>{song.artist}</div>
          </div>
        </div>

        <div
          style={{
            color: isSelected ? "black" : "white",
            fontSize: 12,
            marginBottom: 2,
          }}
        >
          "{song.text}"
        </div>
      </div>
      <div
        style={{
          width: "10%",
          //   display: "flex",
          //   flexDirection: "row",
          //   justifyContent: "flex-start",
          position: "absolute",
          right: 5,
        }}
      >
        {isSelected && <FaCheck />}
      </div>
    </div>
  );
}

export default SelectedSongsItem;
