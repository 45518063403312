import { useState, useEffect, useContext, useRef } from "react";
import {
  FaPhone,
  FaPhoneSlash,
  FaChevronDown,
  FaChevronUp,
} from "react-icons/fa";
import CounterButton from "./CounterButton";
import useSound from "use-sound";
import victorySong from "../assets/sounds/victorySong.mp3";
import { SongsContext } from "../context/SongsContext";
import styve from "./../assets/images/styve.png";

function Team2({
  i,
  setShowCountdown,
  showCountdown,
  showWinner,
  setShowWinner,
  maxScore,
  setWinner,
  initTeamName,
  score,
  updateScore,
}) {
  const [state, dispatch] = useContext(SongsContext);
  const [play, { stop }] = useSound(victorySong);
  //const [score, setScore] = useState(0);
  const [usedPhone, setUsedPhone] = useState(false);
  const [teamName, setTeamName] = useState(initTeamName);
  const teamNameRef2 = useRef(initTeamName);

  useEffect(() => {
    if (initTeamName) {
      teamNameRef2.current.textContent = initTeamName;
    } else {
      teamNameRef2.current.textContent = "Lag 2";
    }
  }, []);

  useEffect(() => {
    if (score === maxScore && showWinner) {
      play();
      // setShowWinner(true);
      // setWinner(i);
    }
  }, [score, maxScore, i, play, setShowWinner, setWinner, showWinner]);

  // useEffect(() => {
  //   localStorage.setItem("team" + i.toString() + "name", teamName);
  // }, [teamName, i]);

  return (
    <div
      style={{
        width: "48%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingTop: "1%",
        paddingLeft: "1%",
        //paddingBottom: "1%",
        marginRight: "0vw",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          //marginLeft: "1vw",
          //marginRight: "3vw",
          height: "20vh",
          width: "18vw",
        }}
      >
        {/* <div
          style={{
            height: "10vh",
            width: "8vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "none",
          }}
        >
          <div
            className="plusCounterButton"
            onClick={() => {
              let newScore = Number(score) + 1;
              updateScore("team2", newScore);
            }}
          >
            <FaChevronUp color="green" />
          </div>
          <div
            className="minusCounterButton"
            onClick={() => {
              let newScore = Number(score) - 1;
              updateScore("team2", newScore);
            }}
          >
            <FaChevronDown color="red" />
          </div>
        </div> */}
        <div
          style={{
            fontSize: "10vh",
            height: "10vh",
            width: "10vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            userSelect: "none",
            textAlign: "center",
          }}
        >
          <div>{Number(score)}</div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          justifyContent: "center",
          width: "18vw",
        }}
      >
        <div
          ref={teamNameRef2}
          style={{
            width: "100%",
            //maxHeight: "8vh",
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 1,
            textOverflow: "ellipsis",
            overflow: "hidden",
            fontSize: "clamp(4vh,4vh, 4vh)",
            marginBottom: 20,
            userSelect: "none",
            textAlign: "right",
            maxLength: "15",
          }}
          contentEditable="true"
          type="text"
          spellCheck="false"
          onInput={(e) => {
            let newValue = e.currentTarget.textContent.slice(0, 14);
            setTeamName(newValue);
          }}
        />
        <div
          style={{
            cursor: "pointer",
            //height: "20%",
            //maxHeight: "20%",
            fontSize: 12,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingtop: 5,
          }}
          onClick={() => {
            setUsedPhone(!usedPhone);
            setShowCountdown(!showCountdown);
          }}
        >
          {!usedPhone ? (
            <FaPhone size="24" color="white" />
          ) : (
            <FaPhoneSlash size="24" color="red" />
          )}
        </div>
      </div>
      <img
        src={styve}
        alt="styve"
        height="100%"
        width={"20%"}
        style={{ marginLeft: "1vw" }}
      />
    </div>
  );
}

export default Team2;
