import { useState } from "react";
import BrowseSongsSong from "./BrowseSongsSong";

const BrowseSongs = ({ newSongs, setNewSongs, allSongs }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredSongs = allSongs.filter(song =>
    song.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    song.artist.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 20,
      }}
    >
      <div style={{ color: "white", width: "90%", textAlign: "left" }}>
        Eller velg fra listen
      </div>
      <input
        type="text"
        placeholder="Søk etter sang eller artist"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        style={{
          width: "90%",
          padding: "10px",
          margin: "20px 20px",
          borderRadius: "5px",
          border: "none",
        }}
      />
      {filteredSongs.map((song, i) => (
        <BrowseSongsSong
          newSongs={newSongs}
          setNewSongs={setNewSongs}
          key={song.title + i}
          song={song}
        />
      ))}
    </div>
  );
};

export default BrowseSongs;
