// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCYXZPj4Oll5oNsJ19mtsNxB6T0ButZ7EY",
  authDomain: "beat4beat-57ff4.firebaseapp.com",
  projectId: "beat4beat-57ff4",
  storageBucket: "beat4beat-57ff4.appspot.com",
  messagingSenderId: "935007067638",
  appId: "1:935007067638:web:c4e9bebcf83d96ed387bd7",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
