import { collection, doc, onSnapshot, updateDoc } from "firebase/firestore";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../Firebase";
import ControlDashboard from "./ControlDashboard";
import GameSession from "./GameSession";

const GameController = ({ isAdmin }) => {
  let { sessionId } = useParams();
  const navigate = useNavigate();
  const [sessionData, setSessionData] = useState({});
  const [sentence, setSentence] = useState("");
  const [words, setWords] = useState([]);
  const [showBoxes, setShowBoxes] = useState([]);
  const [title, setTitle] = useState("");
  const [artist, setArtist] = useState("");
  const [songUri, setSongUri] = useState(null);

  useEffect(() => {
    document.title = "Beat 4 beat";
  }, []);

  useEffect(() => {
    if (sessionData.currentIndex !== null && sessionData.currentIndex > -1) {
      updateSongLocally();
    }
  }, [sessionData.currentIndex]);

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "sessions", sessionId), (doc) => {
      let data = doc.data();
      if (data.songList) {
        setSessionData(data);
      }
    });

    return () => {
      unsub();
    };
  }, [sessionId]);

  const updateSong = async () => {
    if (sessionData.currentIndex < 0) {
      return;
    }
    const nextIndex = sessionData.currentIndex + 1;
    //Update song in db: index and playedSongs
    if (!sessionData.gameStarted) {
      updateSessionData({ gameStarted: true, showAnswer: false });
    }
    if (nextIndex > sessionData.songList?.songs?.length - 1) {
      alert("Tomt for sanger :/");
    } else {
      await updateIndex(nextIndex);
      const item = sessionData.songList?.songs[nextIndex];
      setSentence(item.text);
      setTitle(item.title);
      setArtist(item.artist);
      setSongUri(item.uri);
      let newPreviousSongs = [...sessionData.previousSongs];
      newPreviousSongs.push(item);
      //setPreviousSongs(newPreviousSongs);
    }
  };

  const updateSongLocally = () => {
    const item = sessionData.songList?.songs[sessionData.currentIndex];
    setSentence(item.text);
    setTitle(item.title);
    setArtist(item.artist);
    setSongUri(item.uri);
  };

  useEffect(() => {
    if (!sessionData.songList) {
      return;
    }
    if (
      sessionData.songList.songs?.length > 0 &&
      !sessionData.gameStarted &&
      sessionData.currentIndex === -1
    ) {
      updateSong();
    } else {
    }
  }, [sessionData.songList, sessionData.currentIndex, sessionData.gameStarted]);

  useEffect(() => {
    //updateDoc({ showAnswer: false });
    const wordArray = sentence.split(" ");
    setWords(wordArray);
    //Two random numbers need to be red
    const possibleNumbers = [0, 1, 2, 3, 4, 5];
    const randomNumberOne = possibleNumbers.splice(
      Math.floor(Math.random() * possibleNumbers.length),
      1
    );
    const randomNumberTwo = possibleNumbers.splice(
      Math.floor(Math.random() * possibleNumbers.length),
      1
    );
    //setRedNumbers([randomNumberOne[0], randomNumberTwo[0]]);
    updateSessionData({
      showAnswer: false,
      redNumbers: [randomNumberOne[0], randomNumberTwo[0]],
    });
  }, [sentence]);

  const endGame = () => {
    //Clear everything
    // setsessionData.gameStarted(false);
    // setsessionData.currentIndex(-1);
    // setHasFinishedSetup(false);
    updateSessionData({
      gameStarted: false,
      currentIndex: -1,
      showAnswer: false,
      showBoxes: [],
    });
    navigate("/setup");
  };

  const updateShowBoxes = async (boxIndex) => {
    try {
      let newShowBoxes = [...sessionData.showBoxes];
      if (newShowBoxes?.includes(boxIndex)) {
        //Remove from array
        newShowBoxes = newShowBoxes.filter((i) => i !== boxIndex);
      } else {
        newShowBoxes.push(boxIndex);
      }
      const docRef = await doc(collection(db, "sessions"), sessionId);
      await updateDoc(docRef, {
        showBoxes: newShowBoxes,
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const updateIndex = async (newIndex) => {
    try {
      const docRef = await doc(collection(db, "sessions"), sessionId);
      //const newScore = team === "team1" ? team1score + 1 : team2score + 1;
      await updateDoc(docRef, {
        currentIndex: newIndex,
        showAnswer: false,
        showBoxes: [],
        gameStarted: true,
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const updateMaxScore = async (newMaxScore) => {
    if (
      newMaxScore > sessionData.team1.score ||
      newMaxScore > sessionData.team2.score
    ) {
      return;
    }
    //Update maxscore in db
    try {
      const docRef = await doc(collection(db, "sessions"), sessionId);
      await updateDoc(docRef, {
        maxScore: newMaxScore,
      });
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  //newValues is an object with key value pairs
  const updateSessionData = async (newValues) => {
    //Update maxscore in db
    try {
      const docRef = await doc(collection(db, "sessions"), sessionId);
      await updateDoc(docRef, newValues);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  return isAdmin ? (
    <ControlDashboard
      sessionData={sessionData}
      endGame={endGame}
      updateMaxScore={updateMaxScore}
      words={words}
      title={title}
      artist={artist}
      updateSessionData={updateSessionData}
      updateSong={updateSong}
      updateShowBoxes={updateShowBoxes}
    />
  ) : (
    <GameSession
      sessionData={sessionData}
      endGame={endGame}
      updateMaxScore={updateMaxScore}
      words={words}
      title={title}
      artist={artist}
      updateSessionData={updateSessionData}
      updateSong={updateSong}
      updateShowBoxes={updateShowBoxes}
    />
  );
};

export default GameController;
