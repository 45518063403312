import Song from "./Song";

function ExtendedPlaylist({ songList }) {
  return (
    <div
      style={{
        width: "80%",
        backgroundColor: "#161f33",
        maxHeight: "80vh",
        overflowY: "scroll",
        // padding: "1vh",
        marginLeft: 10,
        scrollbarWidth: "thin",
        scrollbarColor: "#4db193 #161f33",
      }}
    >
      <h3 style={{ color: "white", textAlign: "left", marginLeft: 20 }}>
        {songList.name} - Sanger
      </h3>
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          width: "100%",
          flexWrap: "wrap",
          justifyContent: "flex-start",
        }}
      >
        {songList.songs.map((song, i) => (
          <Song key={song.title + songList.name + i} song={song} />
        ))}
        {/*<AddSong />*/}
      </div>
    </div>
  );
}

export default ExtendedPlaylist;
