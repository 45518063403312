import {songs} from "../assets/songs/songs.js";

export const reducer = (state, action) => {
    switch (action.type) {
      case "update_selectedSongsGlobal":
        return {
          ...state,
          selectedSongsGlobal: action.newSelectedSongsGlobal,
        }
      
        case "update_selectedSongList":
        return {
          ...state,
          selectedSongList: action.newSelectedSongList,
        }

        case "set_is_copied_to_clipboard":
          return {
            ...state,
            isCopiedToClipboard: action.isCopied
          }

          case "update_team1_score":
            return {
              ...state,
              team1score: action.newScore
            }

            case "update_team2_score":
            return {
              ...state,
              team2score: action.newScore
            }
        
          case "set_rememberSelf":
          return {
            ...state,
            rememberSelf: action.rememberSelf
          }

          case "set_noMusic":
          return {
            ...state,
            noMusic: action.noMusic
          }
  
      default:
        return state
    }
  }
  
  export const initialState = {
    selectedSongsGlobal: [],
    selectedSongList: {},
    isCopiedToClipboard: false,
    rememberSelf: false,
    noMusic: false,
    team1score: 0,
    team2score: 0,
  }