import "../App.css";
import Information from "../components/Information";

const Home = () => {
  return (
    <>
      <Information />
    </>
  );
};

export default Home;
