import { useState, useEffect } from "react";
import { Textfit } from "react-textfit";
import useSound from "use-sound";
import wrongSound from "../assets/sounds/sfx-defeat2.mp3";

function NumberBox({
  word,
  i,
  backgroundColor,
  words,
  showAnswer,
  showBoxes,
  updateShowBoxes,
  sessionStorage,
}) {
  const [play] = useSound(wrongSound);
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    setIsOpened(false);
  }, [word, words]);

  useEffect(() => {
    showAnswer && setIsOpened(true);
  }, [showAnswer]);

  useEffect(() => {
    if (!showAnswer) {
      let shouldShowBox = showBoxes?.includes(i);
      setIsOpened(shouldShowBox);
      if (shouldShowBox && sessionStorage.redNumbers.includes(i)) {
        // play();
      }
    }
  }, [showBoxes, showAnswer, i, play, sessionStorage.redNumbers]);

  return isOpened ? (
    <div
      className="numberBox"
      style={{
        height: "100%",
        width: (90 / words.length).toString() + "vw",
        //backgroundColor: 'white',
        color: isOpened ? backgroundColor : "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        //fontSize: isOpened ? "7vh" : "13vh",
        //fontWeight: 'bold',
        //fontFamily: isOpened ? 'Roboto' : 'Brush Script MT',
        border: "1px solid grey",
        //borderRadius: 10,
        cursor: "pointer",
        userSelect: "none",
        //max: 5,
        perfectFit: false,
        //padding: "1vw",
        overflow: "hidden",
        fontSize: "5vh",
        maxFontSize: "7vh",
      }}
      mode="single"
      onClick={() => {
        setIsOpened(!isOpened);
        updateShowBoxes(i);
      }}
    >
      {word}
    </div>
  ) : (
    <div
      className="numberBox"
      style={{
        height: "100%",
        width: (90 / words.length).toString() + "vw",
        //backgroundColor: 'white',
        color: isOpened ? backgroundColor : "black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: isOpened ? "7vh" : "11vh",
        //fontWeight: 'bold',
        //fontFamily: isOpened ? 'Roboto' : 'Brush Script MT',
        border: "1px solid grey",
        //borderRadius: 10,
        cursor: "pointer",
        userSelect: "none",
      }}
      onClick={() => {
        setIsOpened(!isOpened);
        if (backgroundColor === "red") {
          console.log("Playing defeat song from NumberBox onClick");
          play();
        }
        updateShowBoxes(i);
      }}
    >
      <div style={{}}>{i + 1}</div>
    </div>
  );
}

export default NumberBox;
