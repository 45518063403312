import { useState } from "react";
import { FaPlus } from "react-icons/fa";
import BrowseSongsSong from "./BrowseSongsSong";
import PlaylistInput from "./PlaylistInput";
import SelectedSongsItem from "./SelectedSongsItem";
import Song from "./Song";

const SelectedSongs = ({ newSongs, setNewSongs, addNewPlaylist }) => {
  return (
    <div
      style={{
        flexDirection: "column",
        display: "flex",
        width: "70%",
      }}
    >
      <div
        style={{
          color: "white",
          width: "90%",
          textAlign: "left",
          marginLeft: "1%",
        }}
      >
        Valgte sanger ({newSongs.length})
      </div>
      <div
        style={{
          marginTop: 5,
          flexDirection: "row",
          display: "flex",
          width: "100%",
          flexWrap: "wrap",
          justifyContent: "flex-start",
          minHeight: "8vh",
          maxHeight: "65vh",
          backgroundColor: "#3d3d5b",
          borderRadius: 10,
          padding: 10,
          overflowY: "scroll",
        }}
      >
        {newSongs.map((song, i) => (
          <SelectedSongsItem
            newSongs={newSongs}
            setNewSongs={setNewSongs}
            key={song.title + "newPlaylist" + i}
            song={song}
          />
        ))}
      </div>
      <div
        onClick={addNewPlaylist}
        style={{
          height: "2vh",
          width: "20%",
          backgroundColor: "#4db193",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 5,
          cursor: "pointer",
          padding: 5,
          marginTop: 10,
          fontSize: 14,
        }}
      >
        <FaPlus color="black" />
        Opprett spilleliste
      </div>
    </div>
  );
};

export default SelectedSongs;
