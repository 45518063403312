import { useNavigate } from "react-router-dom";

function Information() {
  const navigate = useNavigate();
  return (
    <div
      style={{
        color: "white",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <h1>Velkommen til Beat 4 beat</h1>
      <div>
        <h2>NB! Skjul skjermen for deltagerne før du går videre</h2>
        <div style={{ textAlign: "left", fontWeight: "bold" }}>Regler:</div>
        <ol style={{ textAlign: "left", width: "100%" }}>
          <li>Deltagerne deles inn i to lag</li>
          <li>
            Bak hver luke er det et ord. Alle lukene utgjør til sammen en
            setning i en sang.
          </li>
          <li>Lag 1 starter med å velge en luke.</li>
          <li>Ordet bak luken er enten rødt eller blått.</li>
          Blått - velg én av følgende, om de lykkes får de gjenta fra steg 3:
          <ul>
            <li>1 poeng: Syng på en sang med ordet i seg</li>
            <li>3 poeng: Gjett og syng sangen alle lukene utgjør</li>
          </ul>
          Rødt:
          <ul>
            <li>
              Turen går videre til det andre laget som gjentar prosessen fra
              steg 3
            </li>
          </ul>
          <li>Første lag som når 20 (dette kan endres) poeng vinner!</li>
        </ol>
      </div>
      <div
        key="startGame"
        onClick={() => navigate("/setup")}
        style={{
          height: "4vh",
          width: "20%",
          backgroundColor: "#4db193",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 5,
          cursor: "pointer",
          padding: 5,
          marginTop: 10,
          fontSize: 18,
          alignSelf: "center",
          color: "black",
        }}
      >
        Kom i gang
      </div>
    </div>
  );
}

export default Information;
