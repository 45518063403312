import { FaPlus, FaMinus } from 'react-icons/fa';

function CounterButton({ type, onClickFunction }) {
	const plus = type === 'plus' ? true : false;
	return (
		<div
			className={plus ? 'plusCounterButton' : 'minusCounterButton'}
			style={{
				height: '100%',
				width: '20%',
				//backgroundColor: plus ? 'green' : 'red',
				borderTopLeftRadius: plus ? 0 : 10,
				borderBottomLeftRadius: plus ? 0 : 10,
				borderTopRightRadius: plus ? 10 : 0,
				borderBottomRightRadius: plus ? 10 : 0,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				textAlignVertical: 'center',
				verticalAlign: 'top',
				cursor: 'pointer',
				userSelect: 'none',
			}}
			onClick={onClickFunction}
		>
			{plus ? <FaPlus className='counterButtonIcon' /> : <FaMinus className='counterButtonIcon' />}
		</div>
	);
}

export default CounterButton;
